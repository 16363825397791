<template>
    <div>
        <div>
            <b-button v-b-toggle.sidebar-right>Toggle Sidebar</b-button>
            <b-sidebar id="sidebar-right" title="Sidebar" right shadow>
                <div class="px-3 py-2">
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras
                        justo odio, dapibus ac facilisis in, egestas eget quam.
                        Morbi leo risus, porta ac consectetur ac, vestibulum at
                        eros.
                    </p>
                    <b-img
                        src="https://picsum.photos/500/500/?image=54"
                        fluid
                        thumbnail
                    ></b-img>
                </div>
            </b-sidebar>
        </div>
        <div>content</div>
    </div>
</template>

<script>
import { BButton, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BButton,
        BSidebar,
    },
    directives: {
        "b-toggle": VBToggle,
    },
};
</script>
